<template>
  <div class="pop" >
    <div class="popWrap" style="width: 100%;max-width: 100%;  height: 100%; overflow-y: auto;">
      <div class="box one">
        <PrivacyPolicy v-if="termsNum == 1"/>
        <TermsOfService v-if="termsNum == 2"/>
        <LocationBasedServices v-if="termsNum == 3"/>
      </div>
    </div>
  </div>
</template>

<script>
import PrivacyPolicy from "../termsAndCondition/PrivacyPolicy";
import TermsOfService from "../termsAndCondition/TermsOfService";
import LocationBasedServices from "../termsAndCondition/LocationBasedServices";

export default {
  components: { PrivacyPolicy, TermsOfService, LocationBasedServices },
  data() {
    return {
      termsNum:null,
    };
  },
  created() {
    this.termsNum = this.$route.params.termsNum;
  },
  mounted() {
  },
  methods: {

  }
};
</script>
